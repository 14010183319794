<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button :disabled="entity.status > 0 && entity.status != 4" v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                        <md-button :disabled="entity.status > 0" v-shortkey="['ctrl', 'l']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp"><span>L</span>ưu tạm thời<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.estimateCode.$error }">
                                <label for="estimateCode">Số dự toán</label>
                                <md-input disabled name="estimateCode" v-model="entity.estimateCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.estimateCode.required">Vui lòng nhập số dự toán</span>
                            </md-field>
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Người dự toán</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn người dự toán</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="radio-group">
                                <label>Đơn vị sửa chữa</label>
                                <div class="form-control ">
                                    <md-radio v-model="entity.isOutsource" :value="true">Thuê ngoài</md-radio>
                                    <md-radio v-model="entity.isOutsource" :value="false">PPL sửa chữa</md-radio>
                                </div>
                            </div>
                            <div class="form-control">
                                <md-autocomplete :disabled="!entity.isOutsource" v-model="repairCompanyName" @md-selected="getRepairCompanySelected" :md-options="repairCompanies" @md-changed="getRepairCompanies"  @md-opened="getRepairCompanies" :class="{'md-invalid': submitted && entity.isOutsource && entity.repairCompanyId == null }">
                                    <label>Đơn vị sửa chữa</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && entity.isOutsource && entity.repairCompanyId == null">Vui lòng chọn đơn vị sửa chữa</span>
                                </md-autocomplete>
                                <md-button v-show="entity.isOutsource" @click="openRepairCompany()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="paymentType">Loại dự toán<span class="label-require">(*)</span></label>
                                <md-select v-model="entity.paymentType" name="paymentType">
                                    <md-option v-for="item in paymentTypeList" :key="item.id" :value="item.id">{{item.text}}</md-option>
                                </md-select>
                            </md-field>
                            <div v-if="entity.paymentType == 2" class="form-control">
                                <md-autocomplete v-model="companyName" @md-selected="getThirdPartySelected" :md-options="companies" @md-changed="getThirdParty"  @md-opened="getThirdParty" :class="{'md-invalid': submitted && entity.paymentType == 2 && entity.paymentCompanyId == null }">
                                    <label>Đối tác thanh toán</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && entity.paymentType == 2 && entity.paymentCompanyId == null">Vui lòng chọn đơn vị thanh toán</span>
                                </md-autocomplete>
                                <md-button @click="openThirdParty()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                            <div v-if="entity.paymentType == 3" class="form-control">
                                <md-autocomplete v-model="paymentStaffName" @md-selected="getPaymentStaffSelected" :md-options="paymentStaffs" @md-changed="getPaymentStaffs"  @md-opened="getPaymentStaffs" :class="{'md-invalid': submitted && entity.paymentType == 3 && entity.paymentStaffId == null }">
                                    <label>Thanh toán nhân viên</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && entity.paymentType == 3 && entity.paymentStaffId == null">Vui lòng chọn nhân viên thanh toán</span>
                                </md-autocomplete>
                                <md-button @click="openPaymentStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.estimateDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.estimateDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.estimateDate.required">Vui lòng chọn ngày dự toán</span>
                            </md-datepicker>
                            <div style="display:flex;">
                                <md-field>
                                    <label for="note">Tổng dự toán</label>
                                    <md-input name="pay" :value="formatNumber(entity.pay)" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-body form-tab" style="padding: 0;">
                        <div class="row">
                            <div class="col l-12 m-12 c-12">
                                <md-tabs>
                                    <md-tab id="tab-supplies" class="tab-content" md-label="Nhu cầu vật tư">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addDetailRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th>
                                                                <th style="width:120px">Hành động</th>
                                                                <th style="width:10%;">Đề nghị</th> 
                                                                <th style="width:10%;">Phương tiện</th> 
                                                                <th style="width:10%;">Vật tư</th> 
                                                                <th style="width:10%;">Nội dung</th> 
                                                                <th style="width:8%;">Nguồn</th>
                                                                <th style="width:8%;">ĐVT</th> 
                                                                <th style="width:8%;">Số lượng</th> 
                                                                <th style="width:8%;">Đơn giá</th>
                                                                <th style="width:8%;">Vat</th> 
                                                                <th style="width:8%;">Thành tiền</th>
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.details" :key="'detail-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="grid-action">
                                                                    <a @click="delDetailRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.request.requestCode" class="form-control" type="text">
                                                                        <md-button @click="openRequest(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm số đề nghị</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.vehicle.licensePlate" :class="{'is-error': $v.entity.details.$each[index].vehicleId.$error}" class="form-control" type="text">
                                                                        <md-button @click="openVehicle(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm phương tiện</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.title" :class="{'is-error': $v.entity.details.$each[index].title.$error}" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.note" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.sourceType.name" :class="{'is-error': $v.entity.details.$each[index].source.$error}" class="form-control" type="text">
                                                                        <md-button @click="openSource(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm nguồn</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.unit" :class="{'is-error': $v.entity.details.$each[index].unit.$error}" class="form-control" type="text">
                                                                        <md-button @click="openUnit(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm đơn vị</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="changeAmount(item)" v-model="item.quantity" :class="{'is-error': $v.entity.details.$each[index].quantity.$error}" v-bind="numberF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="changeAmount(item)" v-model="item.price" :class="{'is-error': $v.entity.details.$each[index].price.$error}" v-bind="currency" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="changeAmount(item)" v-model="item.vat" v-bind="vatF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number disabled v-model="item.amount" v-bind="currency" class="form-control currency"></number>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="11">
                                                                    <div class="sum-title">Tổng</div>
                                                                </td>
                                                                <td class="center">
                                                                    <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(this.entity.amount)}}</div>
                                                                </td> 
                                                            </tr>
                                                            <tr>
                                                                <td colspan="11">
                                                                    <div class="sum-title">Thuế VAT</div>
                                                                </td>
                                                                <td class="center">
                                                                    <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(this.entity.vat)}}</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="11">
                                                                    <div class="sum-title">Tổng cộng</div>
                                                                </td>
                                                                <td class="center">
                                                                    <div style="font-weight: bold;text-align: right;padding-right: 5px;">{{formatNumber(this.entity.pay)}}</div>
                                                                </td> 
                                                            </tr>
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th> 
                                                                <th style="width:20%;">Bộ phận/phòng</th> 
                                                                <th style="width:20%;">Người phê duyệt</th> 
                                                                <th style="width:23%;">Tiêu đề</th>
                                                                <th style="width:15%;">Chức năng</th>
                                                                <th style="width:10%;">Thứ tự</th> 
                                                                <th style="width:150px">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.dept.name" :class="{'is-error': $v.entity.approveList.$each[index].deptId.$error}"  class="form-control" type="text">
                                                                        <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.staff.fullName" :class="{'is-error': $v.entity.approveList.$each[index].staffId.$error}"  class="form-control" type="text">
                                                                        <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.title" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <md-field>
                                                                        <md-select v-model="item.isCommenter" name="approveTypes" id="approveTypes">
                                                                            <md-option v-for="obj in approveTypes" :key="'type' + obj.value" :value="obj.value">{{obj.text}}</md-option>
                                                                        </md-select>
                                                                    </md-field>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.orderSort" class="form-control" type="number" style="text-align: center;">
                                                                    </div>
                                                                </td> 
                                                                <td class="grid-action">
                                                                    <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-files" class="tab-content" md-label="File dự toán">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <upload ref="upload" :updateFileInParent="updateFile"/>
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                </md-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
        <approveList ref="approveList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
        <unitList ref="unitList" :group="groupUnit" title="đơn vị" @close="closeUnit"/>
        <sourceList ref="sourceList" :group="group" title="nguồn vật tư" @close="closeSource"/>
        <repairCompanyList ref="repairCompanyList" title="đơn vị sửa chữa" :type="repairCompanyType" @close="closeRepairCompany"/>
        <requestList ref="requestList" @close="closeRequest"/>
        <paymentStaffList ref="paymentStaffList" @close="closePaymentStaff"/>
        <companyList ref="companyList" title="công ty" :type="companyType" @close="closeThirdParty"/>
   </div>
</template>
<script>
    import repairEstimateService from '../../../api/repairEstimateService';
    import staffService from '../../../api/staffService';
    import approveSettingService from '../../../api/approveSettingService';
    import companyService from '../../../api/companyService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import staffList from '../../../components/popup/_StaffList.vue';
    import approveList from '../../../components/popup/_StaffList.vue';
    import vehicleList from '../../../components/popup/_VehicleList.vue';
    import deptList from '../../../components/popup/_DepartmentList.vue';
    import repairCompanyList from '../../../components/popup/_CompanyList.vue';
    import unitList from '../../../components/popup/_ProductUnitList.vue';
    import sourceList from '../../../components/popup/_TypeList.vue';
    import requestList from '../../../components/popup/_RequestRepairList.vue';
    import upload from '../../../components/Upload.vue';
    import paymentStaffList from '../../../components/popup/_StaffList.vue';
    import companyList from '../../../components/popup/_CompanyList.vue';

    export default ({
        components: {
            staffList,
            vehicleList,
            deptList,
            approveList,
            repairCompanyList,
            unitList,
            sourceList,
            requestList,
            upload,
            paymentStaffList,
            companyList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật dự toán sửa chữa'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                companyType: 0,
                repairCompanyType: common.companyType.repair,
                group: common.groupCode.repairSourceProduct,
                groupUnit: common.groupCode.repairProductUnit,
                entity: { id: 0, paymentType: 1,  paymentStaffId: null,  paymentCompanyId: null, files: [], approveList: [], details: [], repairCompanyId: null, staffId: '', staff: {}, estimateCode: '', estimateDate: null, isOutsource: false, objectType: 2, status: 0, amount: 0, vat: 0, pay: 0 },
                staffName: '',
                staffs: [],
                approveTypes: common.approveTypes,
                approve: { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, deptId: 0, staff: { id: 0, fullName: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, objectType: 2},
                repairCompanyName: '',
                repairCompanies: [],
                detail: { id: 'id_' + common.getFakeId(), requestId: null, request: { requestCode: '' }, vehicleId: 0, vehicle: { id: 0, code: '', licensePlate: '' }, title: '', unit: '', source: '', sourceType: { code: '', name: '' }, quantity: 0, price: 0, amount: 0, vat: 0, note: '' },
                numberF: common.numberF,
                currency: common.currencyF,
                vatF: common.vatF,
                paymentTypeList: common.paymentType,
                paymentStaffName: '',
                paymentStaffs: [],
                companyName: '',
                companies: [],
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật dự toán sửa chữa';
               this.getById();
            }
            else{
               this.title = 'Thêm mới dự toán sửa chữa';
               this.entity.estimateDate = common.dateNow;
               this.getByAccount();
               this.entity.approveList.push(this.approve);
               this.getApproveSetting();
               this.entity.details.push(this.detail);
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            closeThirdParty(item){
                this.companyName = item.companyAlias;
                this.entity.paymentCompanyId = item.id;
                this.$refs.companyList.close();
            },

            getThirdPartySelected(val){
                this.entity.paymentCompanyId = val.id;
                this.companyName = val.companyAlias;
            },

            openThirdParty(){
                this.$refs.companyList.open();
            },

            getThirdParty(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: 0, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.companies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Payment Sales
            closePaymentStaff(item){
                this.paymentStaffName = item.fullName;
                this.entity.paymentStaffId = item.id;
                this.$refs.paymentStaffList.close();
            },

            getPaymentStaffSelected(item){
                this.paymentStaffName = item.fullName;
                this.entity.paymentStaffId = item.id;
            },

            openPaymentStaff(){
                this.$refs.paymentStaffList.open();
            },

            getPaymentStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.paymentStaffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            calSummary(){
                this.entity.amount = 0;
                this.entity.vat = 0;
                this.entity.pay = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    this.entity.amount = parseFloat(this.entity.amount) + (this.entity.details[i].quantity * this.entity.details[i].price);
                    this.entity.pay = parseFloat(this.entity.pay) + parseFloat(this.entity.details[i].amount);
                }
                this.entity.vat = this.entity.pay - this.entity.amount;
            },

            //Change quantity
            changeAmount(item){
                let vat = 0;
                const selected = this.entity.details.findIndex(x => x.id == item.id);
                if(parseFloat(this.entity.details[selected].vat) > 0){
                    vat = (this.entity.details[selected].quantity * this.entity.details[selected].price) * (parseFloat(this.entity.details[selected].vat) / 100);
                }
                this.entity.details[selected].amount = vat + (this.entity.details[selected].quantity * this.entity.details[selected].price);
                
                this.calSummary();
            },

            updateFile(files){
                this.entity.files = files;
            },

            //Vehicle 
            openVehicle(id){
                this.selectedId = id;
                this.$refs.vehicleList.open();
            },

            closeVehicle(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].vehicle = item;
                this.entity.details[selected].vehicleId = item.id;
                this.$refs.vehicleList.close();
                this.selectedId = '';
            },

            //Detail
            delDetailRow(item){
                const index = this.entity.details.findIndex(x => x.id == item.id);
                this.entity.details.splice(index, 1);
                this.calSummary();
            },

            addDetailRow(){
                let item =  { id: 'id_' + common.getFakeId(), requestId: null, request: { requestCode: '' }, vehicleId: 0, vehicle: { id: 0, code: '', licensePlate: '' }, title: '', unit: '', source: '', sourceType: { code: '', name: '' }, quantity: 0, price: 0, amount: 0, vat: 0, note: '' };
                this.entity.details.push(item);
            },

            //Request
            closeRequest(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].requestId = item.id;
                this.entity.details[selected].request = item;
                this.entity.details[selected].vehicle = item.vehicle;
                this.entity.details[selected].vehicleId = item.vehicle.id;
                this.$refs.requestList.close();
                this.selectedId = '';
            },

            openRequest(id){
                this.selectedId = id;
                this.$refs.requestList.open();
            },

            getCode(staffId){
                this.setLoading(true);
                repairEstimateService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.estimateCode = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Repair Company
            closeRepairCompany(item){
                this.repairCompanyName = item.companyName;
                this.entity.repairCompanyId = item.id;
                this.$refs.repairCompanyList.close();
            },

            getRepairCompanySelected(val){
                this.entity.repairCompanyId = val.id;
                this.repairCompanyName = val.companyName;
            },

            openRepairCompany(){
                this.$refs.repairCompanyList.open();
            },

            getRepairCompanies(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.repair, code: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.repairCompanies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.requestRepair).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId() + i, status: 1, dept: { code: '', name: '' }, staff: { id: 0, code: '', fullName: '' }, deptId: 0, staffId: 0, title: '', note: '', orderSort: 1, typeCode: String(common.approveType.requestRepair)};
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 2 };
                this.entity.approveList.push(item);
            },

            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].staff = item;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept = item.department;
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
                this.getCode(val.id);
                this.entity.approveList[0].staffId = val.id;
                this.entity.approveList[0].staff = val;
                this.entity.approveList[0].deptId = val.department.id;
                this.entity.approveList[0].dept = val.department;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.paymentType == 2 && this.entity.paymentCompanyId == null){
                    return;
                }
                if(this.entity.paymentType == 3 && this.entity.paymentStaffId == null){
                    return;
                }
                if(this.entity.isOutsource && this.entity.repairCompanyId == null){
                    messageBox.showWarning("Vui lòng chọn đơn vị sửa chữa ngoài");
                    return;
                }
                if(this.entity.details.length > 0){
                    this.entity.details = this.entity.details.filter(item => !(item.vehicleId == 0));
                    this.entity.details.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.details.length == 0){
                    messageBox.showWarning("Vui lòng chọn phương tiện");
                    return;
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.deptId == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length <= 1){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                repairEstimateService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/repair-estimate');
                    }
                    else{
                        this.entity.status = 1;
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                repairEstimateService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/repair-estimate');
                    }
                    else{
                        this.entity.status = 1;
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                repairEstimateService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = this.entity.staff.fullName;
                        
                        if(this.entity.repairCompanyId > 0) {
                            this.repairCompanyName = this.entity.repairCompany.companyName;
                        }
                        if(this.entity.paymentStaffId > 0) {
                            this.paymentStaffName = this.entity.paymentStaff.fullName;
                        }
                        if(this.entity.paymentCompanyId > 0) {
                            this.companyName = this.entity.paymentCompany.companyName;
                        }
                        this.$refs.upload.loadData(this.entity.files);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.currentUser = response.data;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Source
            closeSource(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].sourceType = item;
                this.entity.details[selected].source = item.code;
                this.$refs.sourceList.close();
                this.selectedId = '';
            },

            openSource(id){
                this.selectedId = id;
                this.$refs.sourceList.open();
            },

            //Unit
            closeUnit(item){
                const selected = this.entity.details.findIndex(x => x.id == this.selectedId);
                this.entity.details[selected].unit = item.unitName;
                this.entity.details[selected].unitId = item.id;
                this.$refs.unitList.close();
                this.selectedId = '';
            },

            openUnit(id){
                this.selectedId = id;
                this.$refs.unitList.open();
            },

            back(){
                this.$router.push('/repair-estimate');
            }
        },
        watch: { 
            paymentStaffName: function (val) { 
                if(val == ''){
                    this.entity.paymentStaffId = null;
                }
            },
            companyName: function (val) { 
                if(val == ''){
                    this.entity.paymentCompanyId = null;
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
            repairCompanyName: function (val) { 
                if(val == ''){
                    this.entity.repairCompanyId = null;
                }
            },
            'entity.isOutsource': function (val) {
                if(!val){
                    this.repairCompanyName = '';
                    this.entity.repairCompanyId = null;
                }
            },
            'entity.paymentType': function (val) { 
                if(val == 1){
                    this.companyName = '';
                    this.entity.paymentCompanyId = null;
                    this.paymentStaffName = '';
                    this.entity.paymentStaffId = null;
                }
            },
        },
        validations: {
            entity: {
                deptId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                estimateCode: { required },
                estimateDate: { required },
                details: {
                    $each: {
                        vehicleId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        quantity: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        price: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        title: {  required },
                        unit: {  required },
                        source: {  required },
                    }
                },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
            }
        }
     })

</script>

<style scoped>
    .approve{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve .md-chip{
        height: 25px !important;
        line-height: 25px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
    }

    .approve .md-chip i {
        font-size: 20px;
        padding-right: 5px;
    }
</style>
